<template>
  <div>
    <div class="headerBox">
      <span class="title">{{ $t('site_logo_title') }}</span>
      <div class="info">
        <p class="btnArea">
          <span
            :class="$route.path === '/user/login' ? 'isActive' : ''"
            @click="$router.push('/user/login')"
          >
            {{ $t('login_button') }}
          </span>
        </p>
      </div>
      <LanguageButton class="language-button" />
    </div>
    <router-view />
  </div>
</template>

<script>
import LanguageButton from '@/components/LanguageButton.vue';

export default {
  data() {
    return {};
  },
  components: {
    LanguageButton,
  },
};
</script>

<style scoped lang="scss">
.headerBox {
  position: relative;
  box-sizing: border-box;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  line-height: 72px;

  .language-button {
    position: absolute;
    top: 0;
    right: 100px;
  }
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 160px;

    .userInfo {
      display: flex;
      .username {
        margin-right: 10px;
      }
    }
  }
  .title {
    color: #147fd2;
    font-size: 32px;
    font-weight: bold;
    cursor: pointer;
  }
  .isActive {
    color: #147fd2;
  }
  .btnArea {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 0;

    span {
      cursor: pointer;
      font-weight: bold;
    }
    span:nth-child(2) {
      font-size: 16px;
      margin-left: 20px;
    }
  }
}
</style>
